import * as versionJson from './version.json';

export const environment = {
    production: false,
    ApiURL: '',
    env: 'staging',
    idpURL: 'https://auth.staging.otzy.eu',
    version: versionJson.version,
    idpClientId: '755b420d-72ba-4fff-93f1-140f0cb8c0e2',
    googleApisKey: 'AIzaSyAb2booisSRQI7UQKFVXqEwdl5OuY8Jr84',
};
